	/*
  	Flaticon icon font: Flaticon
    Creation date: 18/01/2021 20:33
    Font generated by <a href="https://www.flaticon.com">flaticon.com</a>. <p>Under <a href="http://creativecommons.org/licenses/by/3.0/">CC</a>: <a data-file="001-right-arrow-angle" href="https://www.flaticon.com/authors/catalin-fertu">Catalin Fertu</a>, <a data-file="003-angle-pointing-to-left" href="https://www.flaticon.com/authors/dave-gandy">Dave Gandy</a>, <a data-file="016-share" href="https://www.flaticon.com/authors/those-icons">Those Icons</a>, <a data-file="017-share-1" href="https://www.flaticon.com/authors/pixel-perfect">Pixel perfect</a>, <a data-file="013-conversation" href="http://www.freepik.com">Freepik</a>, <a data-file="007-finger" href="https://www.flaticon.com/authors/zlatko-najdenovski">Zlatko Najdenovski</a>, <a data-file="014-view" href="https://www.flaticon.com/authors/kiranshastry">Kiranshastry</a></p>  
     
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-right-arrow-angle:before { content: "\f100"; }
.flaticon-double-right-arrows-angles:before { content: "\f101"; }
.flaticon-angle-pointing-to-left:before { content: "\f102"; }
.flaticon-right:before { content: "\f103"; }
.flaticon-back:before { content: "\f104"; }
.flaticon-hand:before { content: "\f105"; }
.flaticon-dont-like-symbol:before { content: "\f106"; }
.flaticon-lock:before { content: "\f107"; }
.flaticon-favorite-heart-button:before { content: "\f108"; }
.flaticon-play-button-1:before { content: "\f109"; }
.flaticon-play-button:before { content: "\f10a"; }
.flaticon-loupe:before { content: "\f10b"; }
.flaticon-conversation:before { content: "\f10c"; }
.flaticon-view:before { content: "\f10d"; }
.flaticon-visibility:before { content: "\f10e"; }
.flaticon-share:before { content: "\f10f"; }
.flaticon-share-1:before { content: "\f110"; }