.LandingPage {
  position: relative;
}
.bg_image {
  background-image: url(../../assets/images/landing_page.jpg);
  height: 100vh; 
  width: 100%;
  background-position: center;
  background-size: cover;
  color: white;
}

.login_side {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  width: 24%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.nav_item {
  text-align: right;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 0;
  float: right;
}
.landing_logo {
  width: 80%;
  margin: auto;
  height: 90px;
}
.swap_banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.motto {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  margin: auto 8px;
}

.input_container {
  width: 90%;
  align-self: center;
}
.register_input {
  border: 1px solid white;
  color: white;
  text-align: center;
  background-color: transparent;
  width: 100%;
  height: 35px;
  outline: none;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.register_input::placeholder {
  color: #ddd
}
.register_input:active {
  border-color: white;
}
.register_button {
  align-self: center;
  width: 90%;
}
.register_button button {
  background: #222;
  border: none;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.select_container {
  margin-bottom: 15px;
}
.select_flex {
  width: 100%; 
  display: flex; 
  justify-content: space-between;
  align-items: center;
}

.register1_select {
  border: 1px solid white;
  background-color: transparent;
  width: 100%;
  height: 28px;
  outline: none;
  color: white;
  text-align: left;
}
.register1_select option {
  color: black;
  padding: 5px;
  font-size: 15px;
}


.meet_div {
  background-color: black;
  color: white;
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meet_div p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
}

.flex_container {
  width: 100%;
}
.first_row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.second_row {
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
}
.fun_image1 {
  width: 50%;
  background-image: url(../../assets/images/square1.jpg);
  background-position: center;
  background-size: cover;
  height: auto;
}
.fun_image2 {
  width: 50%;
  background-image: url(../../assets/images/square2.jpg);
  background-position: center;
  background-size: cover;
  height: auto;
}
.details {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  background-color: #eee;
}
.details h2 {
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}

.details div {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding-right: 35px;
  padding-left: 35px;
}
.details ul {
  margin: auto;
}
.details button {
  background: #222;
  border: none;
  outline: none;
  padding: 10px 20px;
  margin: 5px auto;
  color: white;
  font-weight: 600;
  font-size: 17px;
}

.local_member {
  width: 100%;
}
.local_member p {
  text-align: center;
  font-size: 37px;
  font-weight: 300;
  line-height: 56px;
  color: #222;
}

@media screen and (max-width: 1000px) {
  .login_side {
    width: 35%;
  }
}

@media screen and (max-width: 767px) {
  .meet_div {
    padding: 15px;
    display: block;
    text-align: center;
    margin: auto;
  }
  .meet_div p {
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    text-align: center;
  }
  .flex_container {
    width: 100%;
  }
  .first_row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .second_row {
    flex-direction: column;
    width: 100%;
  }
  .fun_image1 {
    width: 100%;
    height: 350px;
  }
  .fun_image2 {
    width: 100%;
    height: 350px;
  }
  .details {
    height: auto;
    width: 100%;
  }
  .icon_container div {
    width: 100%;
  }
  .icon_container h2 {
    font-size: 26px;
    line-height: 24px;
  }
  .icon_container p {
    line-height: 21px;
  }
}

@media screen and (max-width: 500px) {
  .bg_image {
    height: 100vh;
  }
  .login_side {
    width: 100%;
  }

  .details h2 {
    font-size: 24px;
    padding-left: 7px;
    padding-right: 7px;
    line-height: 28px;
  }
  .details div {
    font-size: 16px;
    line-height: 24px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .local_member {
    width: 100%;
  }

  .local_member p {
    font-size: 30px;
  }
}
