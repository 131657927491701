body {
  scrollbar-width: thin;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.post_write_area {
  width: 50%; 
  margin: auto;
  margin-top: 0
}
.hide_small {
  visibility: visible;
}
@media screen and (max-width: 600px) {
  .post_write_area {
    width: 90%; 
    margin: auto;
    margin-top: 0
  }
}
@media screen and (max-width: 992px) {
  .hide_small {
    display: none;
  }
}